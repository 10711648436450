.auth-page {
      font-family: var(--main-font-family);
      .login-page{
            .wp-inner{
                  width: 760px;
                  margin: 5rem auto 0;

                  .widget{
                        background: #f3f3f3;
                        padding: 15rem 3rem;
                        border-radius: 3rem;
                        .widget-body{
                              .form-login{
                                    .ant-input{
                                          padding: 5px 10px;
                                          color: #000;
                                          border-radius: 30px;
                                          height: 60px;
                                          font-size: 24px;
                                          background: #fff !important;
                                          border: 1px solid #fff;
                                          font-family: var(--main-font-family);
                                          font-weight: 500;
                                          text-align: center;

                                          &::placeholder{
                                                font-size: 16px;
                                          }
                                    }

                                    .ant-input:focus, .ant-input:hover, .ant-input-focused {
                                          border-color: #fff;
                                          border-right-width: 1px;
                                          box-shadow: none;
                                          outline: 0;
                                    }

                                    /* Change the white to any color */
                                    input:-webkit-autofill,
                                    input:-webkit-autofill:hover,
                                    input:-webkit-autofill:focus,
                                    input:-webkit-autofill:active{
                                          -webkit-box-shadow: 0 0 0 30px #fff inset !important;
                                          -webkit-text-fill-color: #000 !important;
                                    }

                                    .ant-input-affix-wrapper {
                                          position: relative;
                                          width: 100%;
                                          min-width: 0;
                                          padding: 0 11px;
                                          font-size: 14px;
                                          line-height: 1.5715;
                                          border: 1px solid #d9d9d9;
                                          border-radius: 30px;
                                          transition: all 0.3s;
                                          display: inline-flex;
                                          background: #fff;
                                          color: #000;
                                          outline: none;
                                          box-shadow: none;

                                          > .ant-input{
                                                border: 1px solid transparent;
                                          }
                                          .ant-input-password-icon{
                                                color: rgba(0, 0, 0, 0.35);
                                          }
                                    }

                                    button[type='submit']{
                                          width: 100%;
                                          height: 48px;
                                          font-size: 16px;
                                          font-weight: 500;
                                    }
                              }
                        }
                  }
            }
      }
}