body{
      --main-font-family : 'Montserrat';
      --button-background-color: #92278f;
      --primary-color: #3742fa;
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active
{
      transition: background-color 5000s ease-in-out 0s;
      -webkit-text-fill-color: #000 !important;
      -webkit-box-shadow: 0 0 0 30px #fff inset !important;
}