.modal-general{
      width: 850px !important;
}
.modal-general{
      font-family: var(--main-font-family);
      .head-modal{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 5rem;
            .title{
                  font-weight: 600;
                  font-size: 26px;
                  text-transform: uppercase;
                  color: #000;
                  margin-bottom: 0;
            }
            button {
                  border: none;
                  font-weight: 500;
                  font-size: 13px;
            }
      }
      .body-modal{
            label{
                  font-weight: 500;
            }
            textarea{
                  font-size: 18px;
            }
            button[type='submit']{
                  height: 40px;
                  width: 100%;
                  font-weight: 500;
            }
            .ant-form-item{
                  margin-bottom: 0.5rem;
            }
            p.display-length{
                  text-align: right;
                  margin-bottom: 3rem;
                  font-size: 13px;
                  font-weight: 500;
                  &.exceed{
                        color: red;
                  }
            }
      }
}

